import React, {useEffect, useState} from "react";
import {Slider} from "@material-ui/core";
import {useTranslation} from '../../tools/i18n';
import {makeStyles} from '@material-ui/styles';
import theme from "../theme";
import DropdownIndicatorOpen from "../../public/images/icons/minus.svg";
import DropdownIndicatorClosed from "../../public/images/icons/plus.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DropdownIndicator from "../../public/images/icons/drop-down.svg";
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles({
	wrapper: {
		marginBottom: 15,
		order: 10,
		[theme.breakpoints.down('md')]: {
			marginBottom: 0
		}
	},
	trigger: {
		display: 'none',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 15,
		padding: '10px 12px 10px 12px',
		borderRadius: 0,
		backgroundColor: '#f5f5f5',
		cursor: 'pointer',
		'& svg': {
			'&.desktop': {
				[theme.breakpoints.down('lg')]: {
					display: 'none'
				}
			},
			'&.mobile': {
				[theme.breakpoints.up('lg')]: {
					display: 'none'
				}
			}
		},
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			marginTop: -1,
			marginBottom: 0,
			padding: '25px 15px 25px 15px',
			backgroundColor: theme.palette.primary.background,
			borderTop: '1px solid #e0e0e0',
			borderBottom: '1px solid #e0e0e0',
			'&.open': {
				marginBottom: 10,
				paddingBottom: 0,
				borderBottom: 'none'
			}
		}
	},
	inner: {
		padding: 10,
		[theme.breakpoints.up('lg')]: {
			padding: '10px 0'
		}
	},
	label: {
		display: 'none',
		margin: '-30px 0 0 0',
		fontSize: 12
	},
	root: (props) => ({
		[theme.breakpoints.up('sm')]: {
			paddingTop: 5,
			minWidth: 200
		}
	}),
	markLabel: {
		top: 35,
		left: '0 !important',
		transform: 'translateX(0)',
		fontSize: 12,
		[theme.breakpoints.up('sm')]: {
			top: 20,
			transform: 'translateX(0%)'
		},
		'& ~ &': {
			left: 'auto !important',
			right: '0 !important',
			transform: 'translateX(0)'
		}
	},
	thumb: {
		marginLeft: 0,
		'&[data-index="1"]': {
			transform: 'translateX(-12px)'
		}
	},
	mark: {
		display: 'none'
	}
}, {name: 'FilterRange'});

export default function FilterRange({name, currentFilter, min, max, onChange}) {
	const priceFormat = useSessionStore((state) => state.methods.formatPrice);
	const classes = useStyles();
	const { t } = useTranslation('attributes');
	const isIPad = useMediaQuery(theme.breakpoints.down('md'));
	const [open, setOpen] = useState(true);
	const [state, setState] = useState({min: (currentFilter && currentFilter.min) ? parseFloat(currentFilter.min) : min, max: (currentFilter && currentFilter.max) ? parseFloat(currentFilter.max) : max});

	useEffect(() => {
		if(isIPad) {
			setOpen(false);
		}
	}, [isIPad]);

	useEffect(() => {
		setState({...state, min: (currentFilter && currentFilter.min) ? parseFloat(currentFilter.min) : min, max: (currentFilter && currentFilter.max) ? parseFloat(currentFilter.max) : max});
	}, [currentFilter, min, max]);

	function onSliderChange(event, values) {
		setState({min: values[0], max: values[1]});
	}

	function formatMarks() {
		const minMax = state;

		if(minMax.min === minMax.max) {
			minMax.min = minMax.min - 1;
		}

		return [{value: minMax.min, label: priceFormat(minMax.min)}, {value: minMax.max, label: priceFormat(minMax.max)}];
	}

	return (
		min >= 0 && max > 0 && (min !== max) ?
			<div className={classes.wrapper}>
				<div className={[classes.trigger, open ? 'open' : ''].join(' ')} onClick={() => setOpen(!open)}>
					{t(name)}
					{open ?
						<>
							<DropdownIndicatorOpen className={'desktop'} width={12}/>
							<DropdownIndicator className={'mobile'} width={12}/>
						</> :
						<>
							<DropdownIndicatorClosed className={'desktop'} width={12}/>
							<DropdownIndicator className={'mobile'} style={{transform: 'rotate(-90deg)'}} width={12}/>
						</>
					}
				</div>
				{open ? <div className={classes.inner}>
					<Slider
						name={name}
						value={[state.min || 0, state.max || 999999]}
						min={parseFloat(min)}
						max={parseFloat(max)}
						steps={1}
						marks={formatMarks()}
						onChange={onSliderChange}
						onChangeCommitted={(event, values) => {
							onChange(name, {min: values[0], max: values[1]});
						}}
						classes={{
							root: classes.root,
							markLabel: classes.markLabel,
							mark: classes.mark,
							thumb: classes.thumb
						}}
						valueLabelDisplay="off"
					/>
				</div> : ''}
			</div>
		: ''
	);
}
