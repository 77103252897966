import React, { useEffect, useState } from 'react';
import { elevateSearch } from 'tools/elevateClient';
import { useTranslation } from 'tools/i18n';
import { Container, Typography } from '@material-ui/core';
import MetaTitle from '../../meta/title';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../../theme';
import RelevateGrid from "../../products/relevateGrid";
import { prepareFacets } from '@functions/elevateOps';
import ElevateListing from "./elevateListing";
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles(
	() => ({
		wrapper: {
			paddingTop: 40,
			paddingBottom: 40
		},
		title: {
			textAlign: 'center',
			'& span': {
				color: '#999'
			}
		},
		subtitle: {
			marginBottom: 40,
			fontSize: 14,
			fontWeight: 300,
			textAlign: 'center'
		},
		searchPhraseAutocomplete: {
			margin: '10px 0 0',
			textAlign: 'center',
			fontStyle: 'italic',
			fontSize: 14,
			fontWeight: 300,
			color: theme.palette.primary.lowlight,
			'& span': {
				fontWeight: 'bold'
			}
		},
		container: {
			position: 'relative'
		},
		sortingWrapper: {

		},
		filterGridWrapper: {
			display: 'flex',
			justifyContent: 'center',
			gap: 24,
			paddingTop: 20,
			['@media only screen and (max-width: 1524px)']: {
				justifyContent: 'flex-end',
			},
			[theme.breakpoints.down('lg')]: {
				paddingLeft: 0,
				paddingRight: 0
			},
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				paddingLeft: 0,
				paddingRight: 0
			},
			[theme.breakpoints.up('lg')]: {
				padding: '0',

				'@media screen and (min-width: 1296px)': {
					'&:after': {
						content: '""',
						width: 'calc(((100% - 1600px) / 2) - 24px)',
						maxWidth: 300,
						flexShrink: 0
					}
				}
			}
		},
		gridWrapper: {
			width: '75%',
			flexGrow: 1,
			margin: 0,
			[theme.breakpoints.down('md')]: {
				width: '100%'
			},
		},
		largeRelevatePadding: {
			['@media only screen and (min-width: 1600px)']: {
				padding: '0 20px',
			},
		},
		largeElevatePadding: {
			['@media only screen and (min-width: 1600px)']: {
				padding: '0 22px',
				maxWidth: '1600px',
				margin: 'auto',
			},
		}

	}),
	{ name: 'SearchResults' }
);

function SearchResults({ query, router }) {
	const { t } = useTranslation(['general', 'attributes']);
	const classes = useStyles();
	const config = useSessionStore((state) => state.session.config);
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	// State
	const [elevateData, setElevateData] = useState({
		facets: [],
		productGroups: [],
		sort: { selected: 'RELEVANCE', options: [] },
		totalHits: 0
	});
	const [params, setParams] = useState({ ...query, initial: true });

	// has Autocorrect
	const [autocorrect, setAutocorrect] = useState(false);

	// Secondary product list
	const [secondaryProducts, setSecondaryProducts] = useState([]);

	useEffect(() => {
		setParams({ ...query, initial: true });
	}, [query]);

	useEffect(async () => {
		let routerData = {
			pathname: router.asPath.split('?')[0],
			query: {
				...params
			},
			shallow: true
		};

		if (routerData.query.page === 1) {
			delete routerData.query.page;
		}

		delete routerData.query.initial;

		if (!params.initial) {
			router.push(routerData);
		}
	}, [params]);

	useEffect(async () => {
		let { q, page, order, initial, ...filters } = query;

		if (q) {
			const isSoldOut = q.toLowerCase().indexOf('slutsåld') >= 0;

			const searchResults = await elevateSearch(
				q,
				order || 'RELEVANCE',
				prepareFacets(filters),
				page,
				config.elevateMarket,
				config.language.languageCode,
				isMobile ? 'mobile' : 'desktop',
				isSoldOut
			);

			setElevateData(searchResults?.primaryList);

			// Set products from secondary list
			if (searchResults?.secondaryList?.productGroups) {
				let moreProducts = [];
				searchResults.secondaryList.productGroups.forEach(productSuggestions => {
					moreProducts.push(productSuggestions.products[0]);
				});

				setSecondaryProducts(moreProducts);
			} else {
				setSecondaryProducts([]);
			}

			if (searchResults.autoCorrect) {
				setParams({ ...params, q: searchResults.autoCorrect.q });
				setAutocorrect(searchResults.autoCorrect.q);
			} else {
				setAutocorrect(false);
			}
		}
	}, [router]);

	return (
		<>
			<MetaTitle title={t('search-results-title', { query: query.q || '' })} />
			<div className={classes.wrapper}>
				<div className={classes.largeElevatePadding}>
					<Typography className={classes.title} variant="h1">
						{t('search-results-title', { query: '' })}
						<span>"{query.q}"</span>
					</Typography>

					{autocorrect ? (
						<Typography
							className={classes.searchPhraseAutocomplete}
							variant="body1"
							dangerouslySetInnerHTML={{
								__html: t('search-phrase-autocomplete', { originalPhrase: query.q })
							}}
						/>
					) : (
						''
					)}

					<Typography className={classes.subtitle} variant="body1">
						{elevateData.totalHits > 0
							? t('search-result-hits', { hitCount: elevateData.totalHits })
							: t('search-result-no-hit')}
					</Typography>

					<ElevateListing router={router} params={query} setParams={setParams} {...elevateData} />
				</div>
				{secondaryProducts.length > 0 ? (
					<>
						<Container className={classes.largeRelevatePadding} disableGutters>
							<Typography
								className={classes.title}
								style={{ marginTop: 40, marginBottom: 20 }}
								variant="h2"
							>
								{t('search-related-products')}
							</Typography>

							<RelevateGrid
								products={secondaryProducts}
								paramsHash={
									typeof btoa !== 'undefined'
										? btoa(JSON.stringify(params))
										: Buffer.from(JSON.stringify(params)).toString('base64')
								}
							/>
						</Container>
					</>
				) : (
					''
				)}
			</div>
		</>
	);
}

export default SearchResults;
