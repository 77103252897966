import { Box, Grid } from "@material-ui/core";
import ProductItem from "./item";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../theme";
import {useTranslation} from "tools/i18n";
import {Alert} from "@material-ui/lab";
import { useEffect, useContext, useState } from 'react';
import urlNormalizer from "tools/urlNormalization";
import {useRouter} from "next/router";
import Image from "react-storefront/Image";
import { elevateClickNotification } from "tools/elevateClient";
import { makeStyles } from "@material-ui/styles";
import {
	getFastDelivery,
	getMainVariant, getMarketingLabel,
	getProductImage,
	getProductVariants, getStock,
	getUUID,
	preventFloatRoundingIssues
} from '@functions/elevateOps';
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles(() => ({
	productGrid: {
		display: 'flex',
		flexWrap: 'wrap',
		gridTemplateColumns: "repeat(2, minmax(0, 50%))",
		gap: 10,
		[theme.breakpoints.up('sm')]: {
			gridTemplateColumns: "repeat(3, minmax(0, 33.333%))",
			gap: 20
		},
		[theme.breakpoints.up('md')]: {
			gridTemplateColumns: "repeat(4, minmax(0, 25%))",
			gap: 20
		}
	},
	productItem: {
		maxWidth: 'none'
	},
	productImage: {
		flexGrow: 1,
		backgroundColor: theme.palette.primary.background,
		'& img': {
			transition: 'opacity 0.25s ease'
		},
		'&:hover img': {
			opacity: 0.9
		}
	},
	noHitItem: {
		gridColumn: '1/5'
	},
	alertMessage: {
		textAlign: "center"
	},
	alert: {
		justifyContent: 'center'
	}
}), {name: 'RelevateProductGrid'});

function RelevateProductGrid({products, paramsHash}) {
	const router = useRouter();
	const classes = useStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const config = useSessionStore((state) => state.session.config);
	const {t} = useTranslation();
	const [showError, setShowError] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (products.length <= 0) {
				setShowError(true);
			} else {
				setShowError(false);
			}
		}, 1200);

		return () => clearTimeout(timer);
	}, [products]);

	async function onClick(product) {
		await elevateClickNotification(
			product.ticket,
			config.elevateMarket,
			config.language.languageCode,
			isMobile ? 'mobile' : 'desktop'
		);
	}

	return (
		<Box component="div" className={[classes.productGrid, 'product-grid'].join(' ')} style={{display: 'grid'}}>
			{products.length > 0 ? products.map((product, key) => {
				let mainVariant = getMainVariant(product);
				let mainImage = getProductImage(product);

				return <ProductItem
					id={getUUID(mainVariant)}
					productNumber={mainVariant.key}
					name={product.title}
					manufacturer={product.brand}
					link={urlNormalizer(product.link, router.basePath)}
					prices={{
						price: mainVariant.sellingPrice,
						listPrice: mainVariant.listPrice,
						isOnSale: mainVariant.sellingPrice < mainVariant.listPrice,
						discount: mainVariant.sellingPrice < mainVariant.listPrice ? {
							amount: mainVariant.sellingPrice - mainVariant.listPrice,
							percentage: preventFloatRoundingIssues(mainVariant.sellingPrice, mainVariant.listPrice)
						} : undefined
					}}
					image={<Image {...mainImage}
					  alt={product.title}
					  className={classes.productImage}
					  lazyOffset={400}
					  notFoundSrc={'/images/placeholder/product.jpg'}
					  contain/>}
					list="Search results"
					variants={getProductVariants(product) ? mainVariant.label : false}
					stock={getStock(mainVariant)}
					fastDelivery={getFastDelivery(mainVariant)}
					labels={getMarketingLabel(mainVariant)}
					available={mainVariant.inStock}
					addToCart={'minimal'}
					isOutlet={product.title.indexOf('OUTLET') >= 0}
					position={key + 1}
					clickCallback={onClick.bind(this, product)}
					elevateTicket={mainVariant.ticket}
					isMobile={isMobile}
					customClasses={{item: classes.productItem}}
					lazyHydrate={false}
					key={paramsHash + key}
					propertyIds={product.propertyIds}
				/>
			}) :
				''
			}
			{ showError ?
				<Grid xs={12} className={classes.noHitItem} item>
					<Alert severity="warning" className={classes.alert} classes={{message: classes.alertMessage}}>{t('search-result-no-hit')}</Alert>
				</Grid>
				:
				''
			}
		</Box>
	);
}

export default RelevateProductGrid;
